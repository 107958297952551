/* --------------------------------------------------------------
    Import CCS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #ffffff;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  // p {
  // 	margin: 0 0 5px 0;
  // }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #footer-info {
    color: #777777;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  float: none;
  a.logo {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 75%;
    max-width: 650px;
    img {
      display: none;
      margin-right: 5px;
      vertical-align: middle;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      display: inline;
      font-size: 21px;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      // font-weight: 600;
      font-family: 'Fjalla One', Impact, Verdana;
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: var(--font-family);
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
}
#banner-wrap {
  ul {
    font-weight: 600;
  }
  &.home {
    background: transparent url(../images/fots-home-banner.jpg) no-repeat 0 bottom/cover;
    #banner {
      position: relative;
      h1 {
        font-family: var(--font-family);
        font-weight: 600;
        color: $theme-secondary;
        text-shadow: none;
        line-height: 30px;
      }
      h2 {
        color: #d74a35;
      }
    }
    .cta-btn {
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      border: 0;
      &.red {
        background: #d23119;
        &:hover {
          background: darken(#d23119, 5%);
        }
      }
    }
    .details {
      color: $theme-secondary;
      ul.star > li:before {
        color: #55b5ed;
      }
    }
    .price {
      color: $theme-secondary;
      text-shadow: none;
    }
  }
  &.inner {
    h1,
    h2 {
      color: #333333;
    }
    position: relative;
    background: #d7ebfa url(../images/banner-wrap-2.jpg) no-repeat 50% bottom / cover;
    .pitch {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}
#course-options {
  .card {
    background: #fff url(../images/section_bg.jpg) no-repeat top right;
    box-shadow: 0 0 20px #e5f5ff inset;
  }
}
#reviews-wrap {
  border-top: 1px solid #e5ecf4;
  background: #fafafa;
  blockquote {
    margin-bottom: 10px;
    font-size: 1.35em;
    line-height: 1.4em;
    font-weight: 400;
    color: #444;
    font-family: Georgia, 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    font-style: italic;
    p {
      color: #777;
      &:after,
      &:before {
        margin-right: 8px;
        display: inline-block;
        content: '\201C';
        color: #ccc;
        font-family: serif;
        font-size: 36px;
        font-weight: 700;
        vertical-align: middle;
        font-style: normal;
      }
      &:after {
        margin-left: 8px;
        content: '\201D';
      }
      a {
        color: inherit;
        border: 0;
      }
    }
  }
  .more-reviews {
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    .shopperlink {
      margin: 0 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.action-wrap {
  background-color: #5a6575;
  .spanish-btn {
    display: inline-block;
    font-family: 'Fjalla One', Impact, 'Open Sans', Verdana;
    color: #fff;
  }
}
.card {
  p.overflow-hidden {
    overflow: hidden;
  }
}
.gradient-wrap {
  background: linear-gradient(145deg, $theme-secondary, $theme-secondary 40%, #469fd3);
}
#courses-wrap {
  background: linear-gradient(145deg, $theme-secondary, $theme-secondary 40%, #469fd3);
  h3 {
    color: $theme-secondary;
    font-weight: bold;
  }
  ul.check > li:before {
    color: $theme-secondary;
  }
  .cta-btn.blue {
    color: #fff;
    font-weight: 400;
  }
}

// custom dropdown CTA styles
.cta-dropdown {
  width: 220px;
  vertical-align: middle;
  display: inline-block;
  span.dropdown {
    padding: 14px;
    padding-bottom: 12px;
    color: #fff;
    font-weight: 400;
    background: $cta-blue !important;
    border: 0;
    height: auto;
    line-height: 1;
    font-size: 21px;
    text-align: center;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 48px;
      width: 220px !important;
      li a {
        color: #333 !important;
        font-size: 14px;
      }
    }
    &:after {
      display: none;
    }
    &:hover {
      background: lighten($cta-blue, 7%) !important;
    }
  }
  &.red {
    span.dropdown {
      background: $cta-red !important;
      &:hover {
        background: lighten($cta-red, 7%) !important;
      }
    }
  }
  &.outline {
    span.dropdown {
      background: #fff !important;
      color: $cta-blue;
      border: 1px solid $cta-blue;
      &:hover,
      &:active {
        background: rgba($cta-blue, 0.1) !important;
      }
      & + .drop {
        top: 53px;
      }
    }
    &.small {
      span.dropdown {
        & + .drop {
          top: 42px;
        }
      }
    }
  }
  &.small {
    span.dropdown {
      padding: 9px 0 7px 0;
      font-size: 18px;
      line-height: 1.35;
      & + .drop {
        top: 40px;
      }
    }
  }
}
.mt-n5 {
  margin-top: -3rem !important;
}
.text-shadow-0 {
  text-shadow: 0 0 0 !important;
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo {
    img {
      display: block;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      display: block;
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
    span.big {
      display: inline-block;
      font-size: 26px;
      font-weight: 400;
    }
    span.small {
      display: inline-block;
    }
  }
  #banner-wrap {
    &.inner {
      .details {
        .pitch {
          min-width: 450px;
          margin-left: 30%;
        }
      }
      .price.burst {
        position: absolute;
        top: 15%;
        left: 30px;
      }
    }
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 16px;
    }
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #banner-wrap {
    &.home #banner {
      h1 {
        line-height: 50px;
      }
      .card {
        max-width: 530px;
      }
    }
    &.inner {
      #banner.four-hour {
        background: transparent url(../images/4-hour-banner.png) no-repeat 5% 100%;
      }
      #banner.eight-hour,
      #banner.twelve-hour,
      #banner.driving-record,
      #banner.tlsae-course {
        background: transparent url(../images/8-hour-banner.png) no-repeat 5% 100%;
      }
      .details {
        .pitch {
          margin-left: 0;
        }
      }
      .price.burst {
        position: absolute;
        top: 15%;
        left: -130px;
      }
      .price.burst.spanish {
        top: 25%;
        left: -165px;
      }
    }
  }
  #video-banner {
    background: #fff url(../images/streaming_banner_bg.jpg) no-repeat 100% bottom;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.8);
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    &.inner {
      .price.burst {
        left: -100px;
      }
    }
  }
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
  #banner-wrap {
    &.inner {
      .price.burst.spanish {
        top: 25%;
        left: -85px;
      }
    }
  }
}
